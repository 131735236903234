.login {
  .logo {
    vertical-align: top;
    width: 45%;
  }

  .common-signin,
  .common-signin-active,
  .google-btn,
  .microsoft-btn {
    width: 100%;
    padding: 0.786rem 1.5rem;
    border-radius: 0.358rem;
    line-height: 1;
    box-shadow: 2px 6px 10px rgba(46, 46, 46, 0.62);
    border: none;
  }

  .common-signin {
    background-color: #c4c4c4;
    color: #5e5873;
  }

  .common-signin-active {
    background-color: #ffc000;
    color: #5e5873;
  }

  .forgot-password-btn {
    background-color: transparent;
    color: #493bf4;
    border: none;
  }

  .google-btn {
    background-color: #fff;
    color: #5e5873;
  }

  .microsoft-btn {
    background-color: #00a2ed;
    color: #fff;
  }

  .advertising {
    background-color: #283046;
    p {
      width: 40%;
      color: #fff;
      font-size: 50px;
      line-height: 70px;
      font-weight: bold;
      .yellow-letters {
        color: #ffc000;
      }
      .blue-letters {
        color: #00a2ed;
      }
    }
  }
}
